<template>
  <van-popup v-model="visible" position="bottom">
    <div class="agreement-wrapper">
      <p class="title">隐私政策</p>
      <p>《中国联通隐私政策》</p>
      <p>中国联通（以下简称“我们”）非常重视用户的隐私和个人信息保护，致力于维持您对我们的信任。我们承诺采取相应的安全保护措施来保护您的个人信息。请在使用我们的移动业务、宽带业务、固话业务、融合业务、IDC服务、ICT服务、云计算服务、大数据服务、物联网服务等各项产品或服务（以下统称“我们的产品或服务”）前，仔细阅读并了解《中国联通用户隐私政策》（以下简称“本隐私政策”）。</p>
      <p>您在使用我们的产品或服务时，我们将按照《中华人民共和国个人信息保护法》等国家法律法规及监管政策的相关要求处理您的个人信息，以及向您提供服务，我们希望通过本隐私政策向您说明：在您使用我们的产品或服务时，我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。</p>
      <p>本隐私政策与您所使用的我们的产品或服务息息相关，对于您行使个人权利及保护您的个人信息至关重要，请您在使用我们的产品或服务前认真阅读并充分理解本隐私政策所写明的内容。</p>
      <p>阅读过程中，如您对我们的隐私政策及服务条款的内容有任何疑问，请联系我们。</p>
      <p>如您签署或点击确认本隐私政策，即表示您已经理解并同意本隐私政策全部内容。</p>
      <p>我们如何收集和使用您的个人信息；</p>
      <p>我们如何使用 Cookie 和同类技术；</p>
      <p>我们如何共享、转移、公开披露您的个人信息；</p>
      <p>我们如何保护您的个人信息；</p>
      <p>我们如何处理未成年人的个人信息；</p>
      <p>您的个人信息如何存储及在全球范围转移；</p>
      <p>本隐私政策如何更新；</p>
      <p>您的权利；</p>
      <p>如何联系我们。</p>
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
      <p>（一）为更好地为您提供服务，中国联通将遵循“合法、正当、必要和诚信”原则，按照相关法律法规及监管政策的规定收集和使用如下您的个人信息：</p>
      <p>1.用户登记的个人身份信息</p>
      <p>根据《中华人民共和国网络安全法》《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等规定，您在办理入网手续时须向我们出示有效证件、提供真实身份信息；同时，为了验证该类信息的准确性和完整性，我们会将您提供的信息与合法存有您信息的机构（政府机关、事业单位、商业机构）进行验证核对；通过核验后，我们将通过识别仪、高拍仪、纸质/电子表单等方式对您的个人身份信息进行登记，通过拍照或在线视频留存您的个人信息、办理入网手续。留存的个人信息主要包括：个人身份及网络身份标识鉴权信息 [主要包括个人用户姓名、有效通信联系方式、有效证件类型及号码、证件登记信息、装机地址、交费账号、电子邮箱、拍照或在线视频方式获得的影像资料、服务账号及密码、密码保护答案、办理的业务]。 如您拒绝提供个人有效证件及真实信息，将无法办理入网手续，无法使用我们的产品或服务。</p>
      <p>2、使用服务过程中产生的信息</p>
      <p>您使用我们提供的服务时，我们可能自动收集以下信息：</p>
      <p>（1）日志信息</p>
      <p>指您使用我们服务时，系统可能通过 cookies、web beacon 或其他方式自动采集的技术信息，包括：</p>
      <p>A.设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址和您的移动设备所用的版本和设备识别码。</p>
      <p>B.您在使用我们服务时搜索和浏览的信息，例如您使用的搜索关键字、访问页面，以及您在使用我们服务时浏览或要求提供的其他信息。</p>
      <p>C．实现服务所必须的基本信息，例如为实现接通您的通话网络所必需的对方号码。</p>
      <p>（2）位置信息</p>
      <p>指您开启设备定位功能或使用我们基于位置提供的相关服务时，我们收集的有关您位置的信息，例如您通过具有定位功能的移动设备使用我们的服务时，我们通过IP地址、GPS、基站、Wi-Fi等方式收集的您的地理位置信息。</p>
      <p>（3）订购信息</p>
      <p>指您通过中国联通营业厅、http://www.10010.com网上营业厅、中国联通短信营业厅、中国联通APP、中国联通授权的代理渠道等正规渠道订购移动通信、宽带接入及其他增值服务或购买商品时产生的记录，如订购关系记录、消费记录等。</p>
      <p>（4）手机账户信息</p>
      <p>指您手机登录账户的信息，例如在您选择使用手机账号登录我们的服务时，我们会获取您的手机账号等相关信息。</p>
      <p>3.为共同向您提供产品服务或改进产品服务的质量或出于对产品服务安全性的考量等合理需要， 我们可能按照相关法律法规及监管政策的要求或经过您的授权从关联公司、合作伙伴及其他受信任的第三方接收您的个人信息及其他信息。 例如当您通过我们关联公司、业务合作伙伴网站订购我们的产品，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单。</p>
      <p>4.在收集过程中，我们将严格遵守相关法律法规及监管政策， 不收集任何法律法规及监管政策禁止收集的信息。</p>
      <p>5.您了解并同意，以下情形中我们使用个人信息无需征得您的授权同意：</p>
      <p>（1）为订立、履行与您的合同所必需；</p>
      <p>（2）为履行法定职责或者法定义务所必需；</p>
      <p>（3）为应对突发公共卫生事件，或者紧急情况下为保护您的生命健康和财产安全所必需；</p>
      <p>（4）在合理的范围内处理您自行公开或者其他已经合法公开的个人信息；</p>
      <p>（5）与国家安全、国防安全有关的；</p>
      <p>（6）与公共安全、重大公共利益有关的；</p>
      <p>（7）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>（8）用于维护所提供的产品或服务的安全稳定运行所必需，例如发现、处置产品或服务的故障；</p>
      <p>（9）法律法规及监管政策规定的其他情形。</p>
      <p>（二）为向您提供服务，在遵守国家法律法规及监管政策的前提下，我们可能将收集到的您的个人信息用于以下目的：</p>
      <p>1.为您提供服务</p>
      <p>（1）在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</p>
      <p>（2）根据国家相关要求，用于旨在推进政府管理、加强社会治理、改善社会民生、建立诚信体系等目的的服务；</p>
      <p>（3）使我们更加了解您如何使用我们的服务，从而针对性地满足您的个人需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他使用我们服务的用户做出其他方面的回应；</p>
      <p>（4）用于向您提供个性化或定制化产品、服务或广告，如您不希望收到这些信息，可通过订购渠道、前往中国联通当地营业厅、拨打10010客服热线等方式中的一种或几种取消订购或订阅。</p>
      <p>（5）通过信息数据分析，帮助我们设计并为您提供更好感知的新服务；如您不希望收到这些信息，可通过订购渠道、前往中国联通当地营业厅、拨打10010客服热线等方式中的一种或几种取消订购。</p>
      <p>（6）评估我们的服务效果，并加以改善；</p>
      <p>（7）软件认证或管理软件升级；</p>
      <p>（8）邀请您参与有关我们产品和服务的调查。</p>
      <p>2.您了解并同意，我们可以通过技术手段对您的个人信息数据进行匿名化处理，匿名化处理的信息将无法识别个人主体。在此情况下我们有权使用已经匿名化的信息，并在符合相关法律法规及监管政策的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用。</p>
      <p>3.当我们要将信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。</p>
      <p>二、我们如何使用 Cookie 和同类技术</p>
      <p>（一）Cookie</p>
      <p>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好或购物篮内的商品等数据。</p>
      <p>我们不会将Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。</p>
      <p>（二）网站信标和像素标签</p>
      <p>除Cookie外，我们还有可能会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</p>
      <p>（三）Do Not Track（请勿追踪）</p>
      <p>很多网络浏览器均设有“Do Not Track”（请勿追踪）功能，该功能可向网站发布“Do Not Track”请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了“Do Not Track”，那么我们的所有网站都会尊重您的选择。</p>
      <p>三、我们如何共享、转移和公开披露个人信息</p>
      <p>（一）共享</p>
      <p>我们不会与中国联通以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
      <p>1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
      <p>2.我们可能会根据法律法规及监管政策要求，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
      <p>3.您授权银行、证券、保险、征信机构等第三方向我们查询、收集您在中国联通的信息，或者我们会在法律法规及监管政策允许范围内及您对第三方的授权范围内向第三方共享您的信息，我们会依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合法律法规及监管政策的前提下，使用您的这些个人信息。</p>
      <p>4.与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们将依照合法、正当、必要、诚信原则，仅出于在能够实现业务目的最小范围内共享您必要的个人信息，且受本隐私政策所声明目的的约束。如相关法律法规、监管政策要求或我们为维护您的权益认为确有必要时，我们亦会在将信息提供给关联公司前征得您的单独同意。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
      <p>我们的关联公司包括中国联通现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</p>
      <p>5.与授权合作伙伴共享： 仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供，为此 我们可能会与合作伙伴共享您的某些个人信息。 例如，在您通过我们的网站购买商品或订购业务时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。 我们将依照合法、正当、必要、诚信原则，仅出于在能够实现业务目的最小范围内共享您必要的个人信息。如相关法律法规、监管政策要求或我们为维护您的权益认为确有必要时，我们亦会在将信息提供给授权合作伙伴前征得您的单独同意。 我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
      <p>目前，我们的授权合作伙伴包括以下3大类型：</p>
      <p>（1）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利、交易安全服务或进行学术研究和调查。</p>
      <p>（2）第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</p>
      <p>（3）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会共享用于识别您个人身份的信息，我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以提升广告及服务信息的有效触达率。</p>
      <p>对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协定，要求他们按照我们的说明、隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
      <p>（二）转移</p>
      <p>我们不会将您的个人信息转移给任何公司、组织和个人，但以下情况除外：</p>
      <p>1.在获取明确同意的情况下转移：获得您的明确同意后，我们会向其他方转移您的个人信息；</p>
      <p>2.在涉及合并、收购或破产清算时，如涉及到个人信息转移，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意；</p>
      <p>3. 相关法律法规规定或我们与您约定的其他情形。</p>
      <p>（三）公开披露</p>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>1.获得您明确同意后；</p>
      <p>2.基于法律的披露：在法律法规、法律程序或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
      <p>四、我们如何保护您的个人信息</p>
      <p>（一）我们承诺将严格按照国家有关规定做好用户个人信息安全防护工作。我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们将采取一切合理可行的措施，（例如身份鉴别、信息收集监测和审计、网络专线传输、防入侵、防病毒、信息加密存储、信息访问的权限管理、信息认证授权和监控审计、信息脱敏、信息溯源等）以及配套的管理体系来保护您的个人信息。同时我们已设立用户信息保护责任部门，建立相关内控制度，对可能接触到您信息的工作人员采取最小够用授权原则；对工作人员进行相关法律法规、隐私政策和安全意识培训，对工作人员处理您的信息的行为进行系统监控。我们并将接受国家相关机构的信息安全检查。</p>
      <p>（二）您需要承诺妥善保存登录的账号、密码等信息，由于您的原因造成账号密码的泄露产生的后果由您承担。为防止信息泄露，我们将采取防泄漏、反爬虫等技术手段，限制频繁查询您的信息等异常操作行为，以防止账号密码泄露或他人冒用身份造成您产生不良行为记录或者您的信息泄露。</p>
      <p>（三）我们已制定个人信息安全事件应急预案，提高应急处理能力。但 由于技术水平限制及可能存在的各种恶意手段，有可能因我们可控范围外的因素而出现安全问题。在发生个人信息安全事件后，我们将按照相关法律法规及监管政策的要求，及时向您告知：安全事件的基本情况和可能影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
      <p>五、 我们如何处理未成年人的个人信息</p>
      <p>（一）我们非常重视对未成年人个人信息的保护。我们将根据国家相关法律法规及监管政策的规定保护未成年人的个人信息。</p>
      <p>（二）如您为不满十四周岁的未成年人，在使用我们的服务前，应在您的父母或其他监护人监护、指导下共同阅读本隐私政策，并由您的父母或其他监护人确认是否同意本隐私政策。</p>
      <p>（三）对于经父母或其他监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或其他监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
      <p>（四）如您是未成年人的父母或其他监护人，请您关注您监护的未成年人是否在取得您的授权同意之后使用我们的服务。如您对您所监护的未成年人的个人信息有疑问，请通过本隐私政策中的联系方式与我们联系。</p>
      <p>（五）如果我们发现自己在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，会设法尽快删除相关数据。</p>
      <p>（六）我们制定了专门的未成年人个人信息处理规则，您可以通过阅读 《中国联通儿童个人信息保护政策及监护人须知》 了解更多信息。</p>
      <p>六、 您的个人信息如何存储及在全球范围转移</p>
      <p>我们在中华人民共和国境内收集的个人信息，将存储在中华人民共和国境内。我们仅在本隐私政策所述目的所必需期间和法律法规及监管规定的时限内保留您的个人信息。如果需要将您的个人信息转移到境外，我们将：</p>
      <p>（一）另行获得您的授权同意，并履行法定程序，在符合届时适用法律的情形下使您的个人信息得到足够的保护；</p>
      <p>（二）该等转移将在符合届时适用法律要求的前提下进行，即便获得您的授权但如果转移方式或者目的不符合相关法律法规规定的，我们也不会进行转移。</p>
      <p>七、本隐私政策如何更新</p>
      <p>我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。基于为给您提供更好的服务以及随着电信业务的发展或法律法规及监管政策变化，我们可能会适时对本隐私政策进行更新。由于我们的用户较多，如本隐私政策发生重大变更，我们将以推送通知、发送邮件、信函、电话或者在中国联通APP、中国联通官方网站发布公告的方式来通知您。若您在本隐私政策修订后继续使用我们的产品或服务，这表示您已充分阅读、理解并愿意接受修订后的本隐私政策约束。</p>
      <p>八、您的权利</p>
      <p>（一）访问您的个人信息</p>
      <p>您有权访问您的个人信息，法律法规规定的例外情形除外。您可前往中国联通当地营业厅、拨打10010客服热线、访问http://www.10010.com网上营业厅、使用中国联通APP等多种方式，查询您的个人信息或修改您的个人资料。</p>
      <p>（二）更正您的个人信息</p>
      <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。</p>
      <p>（三）删除您的个人信息</p>
      <p>在以下情形中，您可以前往中国联通当地营业厅向我们提出删除个人信息的请求：</p>
      <p>1.如果我们处理个人信息的行为违反法律法规；</p>
      <p>2.如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p>3.如果我们处理个人信息的行为违反了与您的约定；</p>
      <p>4.如果您不再使用我们的产品或服务，或您注销了账号；</p>
      <p>5.如果我们不再为您提供产品或服务。</p>
      <p>请您注意，当您或我们协助您成功删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，之前的信息我们仍会按照监管政策要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询，保存期间我们会安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。</p>
      <p>（四）改变您授权同意的范围</p>
      <p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。您可以前往中国联通当地营业厅、访问http://www.10010.com网上营业厅、使用中国联通APP等多种方式设置（如历史记录设置等）来关闭部分功能选项从而撤销部分授权。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理活动的效力。</p>
      <p>（五）响应您的上述请求</p>
      <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的要求。对于您关于用户个人信息的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。在以下情形中，按照法律法规及监管政策要求，我们将无法响应您的请求：</p>
      <p>1.与国家安全、国防安全直接相关的；</p>
      <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>4.有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>6.涉及商业秘密的。</p>
      <p>九、如何联系我们</p>
      <p>公司名称：中国联合网络通信有限公司</p>
      <p>注册地址：北京市西城区金融大街21号联通大厦</p>
      <p>联系电话：10010客服热线</p>
      <p>相关负责人邮箱：hqs-yhys@chinaunicom.cn</p>
      <p>网站：http://www.10010.com</p>
      <p>如您对本隐私政策或您个人信息的相关事宜有任何问题、投诉、意见或建议，您可前往中国联通当地营业厅、拨打10010客服热线、访问http://www.10010.com网上营业厅、使用中国联通APP等多种方式与我们联系。</p>
      <p>一般情况下，我们将在十五个工作日内回复。</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.agreement-wrapper {
  height: 300px;
  overflow: scroll;
  background-color: #fff;
  text-indent: 28px;
  line-height: 25px;
  padding: 10px 10px;
  p {
    font-size: 12px;
  }
  .title {
    text-indent: 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
  }
}
</style>
